import axios from "axios";

const celium = axios.create();
celium.defaults.baseURL = process.env.VUE_APP_CELIUM_BASE_URL;

export default class Celium {
  static getHandoverUtilisation() {
    return celium.get("handover-graphs");
  }
}
