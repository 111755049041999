







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HandoverChart extends Vue {
  @Prop() private title!: string;
  chartOptions = {
    animation: {
      duration: 3000
    },
    chart: {
      backgroundColor: "transparent",
      type: "line",
      style: {
        fontFamily: "Roboto",
        color: "#FFFFFF"
      }
    },
    title: {
      text: "Real Time Network Insight",
      style: {
        fontFamily: "Roboto",
        color: "#FFFFFF"
      }
    },
    legend: {
      itemStyle: {
        fontFamily: "Roboto",
        color: "#FFFFFF"
      }
    },
    subtitle: {
      text:
        "Lightwire runs a congestion free network.<br> Our most utilised handovers are shown here.",
      style: {
        color: "#FFFFFF"
      }
    },
    plotOptions: {
      line: {
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        marker: {
          enabled: false
        },
        enableMouseTracking: false
      }
    },
    series: [
      {
        name: "Chorus - Auckland",
        data: this.aucklandNga,
        color: "#ec1f8c"
      },
      {
        name: "TFF - Hamilton",
        data: this.hamiltonUff,
        color: "#25b2ed"
      },
      {
        name: "Chorus - Hamilton",
        data: this.hamiltonNga,
        color: "#8bc53f"
      },
      {
        name: "TFF - Tauranga",
        data: this.taurangaUff,
        color: "#CD6155"
      },
      {
        name: "Chorus - Wellington",
        data: this.wellingtonNga,
        color: "#fbed29"
      },
      {
        name: "Enable - Christchurch",
        data: this.christchurchEnable,
        color: "#CACFD2"
      },
      {
        name: "Chorus - Christchurch",
        data: this.christchurchNga,
        color: "#f3a72e"
      },
    ],
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify",
        style: {
          color: "#FFFFFF"
        }
      }
    },
    yAxis: {
      title: {
        text: "Handover Utilisation (%)",
        style: {
          fontFamily: "Roboto",
          color: "#FFFFFF"
        }
      },
      labels: {
        format: "{value}%",
        style: {
          color: "#FFFFFF"
        }
      },
      max: 100,
      tickInterval: 25
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() private utilisation!: any;

  get startDate() {
    const date = new Date();
    return date.setDate(date.getDate() - 7);
  }

  // get auckland() {
  //   return this.utilisation.auckland.map((value: [number, number]) => {
  //     return [value[0] * 1000, value[1]];
  //   });
  // }

  get hamiltonUff() {
    return this.utilisation.hamiltonUff.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get hamiltonNga() {
    return this.utilisation.hamiltonNga.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get taurangaUff() {
    return this.utilisation.taurangaUff.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get wellingtonNga() {
    return this.utilisation.wellingtonNga.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get christchurchNga() {
    return this.utilisation.christchurchNga.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get aucklandNga() {
    return this.utilisation.aucklandNga.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get christchurchEnable() {
    return this.utilisation.christchurchEnable.map(
      (value: [number, number]) => {
        return [value[0] * 1000, value[1]];
      }
    );
  }

  get brisbaneNbn() {
    return this.utilisation.brisbaneNbn.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }

  get sydneyNbn() {
    return this.utilisation.sydneyNbn.map((value: [number, number]) => {
      return [value[0] * 1000, value[1]];
    });
  }
}
