






import { Component, Vue } from "vue-property-decorator";
import HandoverChart from "./components/HandoverChart.vue";
import celium from "./celium";

@Component({
  components: {
    HandoverChart
  }
})
export default class App extends Vue {
  utilisationData = {};
  loaded = false;

  mounted() {
    this.loadUtilisation();
  }

  loadUtilisation() {
    return celium.getHandoverUtilisation().then(response => {
      this.utilisationData = response.data;
      this.loaded = true;
    });
  }
}
