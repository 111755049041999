import Vue from "vue";
import App from "./App.vue";
import celium from "./celium";
import "./plugins/highcharts";

Vue.config.productionTip = false;
Vue.prototype.celium = celium;
new Vue({
  render: h => h(App)
}).$mount("#handover-graphs");
